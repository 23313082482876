import Vue from "vue";
import VueRouter from "vue-router";
import Expiration from "../views/Expiration.vue";
import Reminder from "../views/Reminder.vue";
import Unsubscribe from "../views/Unsubscribe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Reminder",
    component: Reminder,
  },
  {
    path: "/upominka/:id",
    name: "Expiration",
    component: Expiration,
  },
  {
    path: "/pripominka-expirace",
    name: "Reminder",
    component: Reminder,
  },
  {
    path: "/u/:id",
    redirect: {
      name: "Expiration",
    },
  },
  {
    path: "/odhlasit-se-z-odberu",
    name: "Unsubscribe",
    component: Unsubscribe,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
