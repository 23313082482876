<template>
  <div v-if="notifications">
    <v-alert
      :value="showNotification"
      :type="notifications.type"
      transition="scale-transition"
    >
      <div v-for="(message, i) in notifications.messages" :key="i">
        {{ message }}
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    showNotification() {
      return this.notifications ? true : false;
    },
  },
};
</script>

<style>
</style>