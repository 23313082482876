<template>
  <v-form
    ref="form"
    v-model="validForm"
    class="mt-4"
    @submit.prevent="onSubmit"
  >
    <v-select
      :rules="required"
      v-model="expiration.month"
      outlined
      label="Zadejte měsíc expirace"
      :items="months"
    >
    </v-select>

    <v-select
      :rules="required"
      v-model="expiration.year"
      outlined
      label="Zadejte rok expirace"
      :items="years"
    >
    </v-select>
    <v-btn
      type="submit"
      class="submit-btn black--text"
      large
      depressed
      color="primary"
      :loading="loading"
    >
      Odeslat
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      months: Array.from({ length: 12 }, (x, i) => i + 1),
      years: null,
      required: [(v) => !!v || "Pole je povinné"],
      validForm: true,
      expiration: {
        month: null,
        year: null,
      },
    };
  },
  props: {
    loading: {
      type: Boolean,
      required: false
    },
    scannedExpirationDate: {
      type: Object,
      required: false,
    },
  },

  watch: {
    scannedExpirationDate() {
      const getMonth = Number(this.scannedExpirationDate.substring(0, 2));
      const getYear = Number(`20${this.scannedExpirationDate.substring(3, 5)}`);

      this.expiration.month = getMonth;
      this.expiration.year = getYear;
    },
  },
  mounted() {
    this.years = [...Array(10).keys()];
    this.years.forEach((el, index) => {
      this.years[index] = el + new Date().getFullYear();
    });

    this.years.unshift(new Date().getFullYear() - 1)
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate();
      if (this.validForm) {
        this.$emit("expirationDate", this.expiration);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  width: 100%;
}
</style>