<template>
  <div>
    <div v-if="successMessage">
      <div class="text-h6 font-weight-regular success-message">
        <div class="mb-8 d-flex">
          <v-icon class="mr-4" color="success" large>
            mdi-check-circle-outline
          </v-icon>
          <div>
            Připomínky byly nastaveny. Před datem expirace EpiPen pera
            Vám bude zaslána připomínka.
          </div>
        </div>
      </div>
    </div>
    <v-form v-else class="reminder" @submit.prevent>
      <div class="text-h4 mb-4">Nastavení data expirace pera EpiPen</div>
      <app-notification />
      <v-btn
        v-if="showBackToHomeBtn"
        depressed
        color="primary black--text mb-4"
        dark
        @click="backToHome"
      >
        Zpět na domovskou stránku
      </v-btn>
      <code-scanner v-if="showScanner" @scannedResult="scannedResult" />
      <div v-else>
        <div>
          Zadejte prosím datum expirace pera, kterou najdete na krabičce
        </div>
        <img class="mt-8" src="../assets/images/epipen-exp.png" alt="" />
      </div>
      <expiration-date-selects
        :scannedExpirationDate="scannedExpirationDate"
        @expirationDate="submitExpirationDate"
      />
    </v-form>
  </div>
</template>

<script>
import AppNotification from "../components/AppNotification.vue";
import CodeScanner from "../components/CodeScanner.vue";
import ExpirationDateSelects from "../components/ExpirationDateSelects.vue";
export default {
  name: "Expiration",
  components: { AppNotification, CodeScanner, ExpirationDateSelects },
  data() {
    return {
      showScanner: false,
      scannedExpirationDate: null,
      successMessage: false,
      showBackToHomeBtn: false,
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.showScanner = true;
    }
  },
  methods: {
    async submitExpirationDate(value) {
      const month = value.month;
      const expirationDate = new Date(value.year, month, 0);
      const monthLeadingZero = ("0" + (expirationDate.getMonth() + 1)).slice(
        -2
      );

      const epipenExpiresAt = `${expirationDate.getFullYear()}-${monthLeadingZero}-${expirationDate.getDate()}`;
      const payload = { epipenExpiresAt: epipenExpiresAt };

      try {
        this.$store.commit("setNotifications", null);
        await this.$axios.put(
          "reminders/expiration",
          {
            ...payload,
          },
          {
            params: {
              accessCode: this.$route.params.id,
            },
          }
        );
        this.successMessage = true;

        this.$vuetify.goTo(0);
      } catch (error) {
        const errorArray = error.response.data.errors;
        const errorCode = errorArray.map((er) => {
          return er.code;
        });

        const errorMessage = errorArray.map((er) => {
          return er.message;
        });

        if (errorCode.includes("unauthorized")) {
          this.$store.commit("setNotifications", {
            type: "error",
            messages: [
              "Tento link již byl použit pro nastavení data expirace, nelze jej opakovaně použít. Pro nastavení nového data, se prosím vraťte na domovskou stránku",
            ],
          });
          this.showBackToHomeBtn = true;
        } else {
          this.$store.commit("setNotifications", {
            type: "error",
            messages: errorMessage,
          });
        }

        this.$vuetify.goTo(0);
      }
    },

    scannedResult(event) {
      this.scannedExpirationDate = event;
    },

    backToHome() {
      this.$store.commit("setNotifications", null);
      this.$router.push({
        name: "Reminder",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reminder {
  border: 1px solid gainsboro;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-top: 1rem;
  background: white;
}
.submit-btn {
  width: 100%;
}
.success-message {
  border: 1px solid gainsboro;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-top: 1rem;
  background: white;
}
</style>
