<template>
  <main class="wrapper">
    <div class="scanner-section">
      <!-- <v-select
        v-model="selectedDeviceId"
        outlined
        label="Výběr fotoaparátu"
        :items="videoDevices"
        item-text="label"
        item-value="deviceId"
        hide-details
      ></v-select> -->
      <div>
        <div class="video-window">
          <video id="video" @click="startButton"></video>
        </div>
        <div class="scan-text" v-if="showScanText" @click="startButton">
          KLIKNĚTE SEM PRO ZÁHÁJENÍ SKENOVÁNÍ
          <div class="text-center mt-4">
            <v-icon large>mdi-data-matrix-scan</v-icon>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
let codeReader = null;

import {
  BrowserMultiFormatReader,
  NotFoundException,
  BarcodeFormat,
  DecodeHintType,
} from "@zxing/library";

export default {
  name: "Scanner",
  data() {
    return {
      selectedDeviceId: null,
      videoDevices: [],
      showScanText: true,
      resultData: null,
    };
  },
  async mounted() {
    const formats = [BarcodeFormat.DATA_MATRIX, BarcodeFormat.QR_CODE];
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    codeReader = new BrowserMultiFormatReader(hints);
    const videoInputDevices = await codeReader.listVideoInputDevices();
    this.videoDevices = videoInputDevices.map(({ label, deviceId }) => ({
      label,
      deviceId,
    }));
  },
  methods: {
    reset() {
      this.result = "";
      this.resultData = {
        GTIN: "",
        batch: "",
        productionDate: "",
        bestBeforeDate: "",
        expirationDate: "",
        serialNumber: "",
      };
    },
    startButton() {
      this.showScanText = false;
      codeReader.decodeFromVideoDevice(
        this.selectedDeviceId,
        "video",
        (result, err) => {
          if (result) {
            const splitter = result.text.charAt(0);
            const split = result.text
              .split(splitter)
              .filter((item) => item !== "");
            // GS1 DataMatrix Guideline
            // https://www.gs1.org/docs/barcodes/GS1_DataMatrix_Guideline.pdf
            // This table shows six examples of common GS1 element strings:
            // Table 2-1 GS1 element strings
            // AI Data definition Format (AI & data)*
            // 01 GTIN N2+N14
            // 10 Batch or lot number N2+X..20
            // 11 Production date (YYMMDD) N2+N6
            // 15 Best before date (YYMMDD) N2+N6
            // 17 Expiration date (YYMMDD) N2+N6
            // 21 Serial number N2+X..20
            this.resultData = {
              GTIN: "",
              batch: "",
              productionDate: "",
              bestBeforeDate: "",
              expirationDate: "",
              serialNumber: "",
            };
            const parseDate = (date) => {
              const s = date.substring(2, 4) + "." + date.substring(0, 2);
              return s;
            };
            split.forEach((item) => {
              if (item.startsWith("01")) {
                this.resultData.GTIN = item.substring(2);
              }
              if (item.startsWith("10")) {
                this.resultData.batch = item.substring(2);
              }
              if (item.startsWith("11")) {
                this.resultData.productionDate = parseDate(item.substring(2));
              }
              if (item.startsWith("15")) {
                this.resultData.bestBeforeDate = parseDate(item.substring(2));
              }
              if (item.startsWith("17")) {
                this.resultData.expirationDate = parseDate(item.substring(2));
              }
              if (item.startsWith("21")) {
                this.resultData.serialNumber = item.substring(2);
              }
            });

            this.$emit("scannedResult", this.resultData.expirationDate);
          }
          if (err && !(err instanceof NotFoundException)) {
            this.resultData = err.message;
            this.resultData = {
              GTIN: "",
              batch: "",
              productionDate: "",
              bestBeforeDate: "",
              expirationDate: "",
              serialNumber: "",
            };
          }
        }
      );
      console.log(
        `Started continous decode from camera with id ${this.selectedDeviceId}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.scanner-section {
  position: relative;

  .video-window {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    max-width: 25rem;
    margin: auto;
    height: 25rem;
    position: relative;
    background: gainsboro;
    border: 1px dashed gray;
    margin-top: 1rem;
    video {
      border: none;
      height: 800vw;
      width: 800vw;
      max-width: none;
      transform-origin: center;
      transform: scale(0.5);
    }
  }

  .scan-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
</style>
