<template>
  <v-form ref="form" class="unsubscribe" @submit.prevent="onSubmit">
    <div>
      <app-notification class="mt-4" />
    </div>

    <section>
      <div class="section-title">
        <div
          class="d-flex flex-column align-center flex-md-row align-md-start mb-4"
        >
          Pokud si již nepřejete dostávat notifikace o expiraci pera EpiPen
          zadejte prosím telefonní číslo, kterým jste se registrovali k
          notifikacím.
        </div>
        <div>
          <v-text-field
            v-model="phone.number"
            :rules="[phoneRules]"
            outlined
            label="Zadejte telefonní číslo"
          >
          </v-text-field>
        </div>
      </div>

      <div class="text-right mt-8">
        <v-btn depressed color="error" large type="submit">Odhlásit se</v-btn>
      </div>
    </section>

    <vue-recaptcha
      style="display: none"
      size="invisible"
      ref="recaptcha"
      sitekey="6LdKYKcgAAAAAECvyEpWvb3FLx9NRgoxuMRKLFFf"
      @verify="verifyRecaptchaMethod"
      @render="recaptchaExecute"
    ></vue-recaptcha>
  </v-form>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import AppNotification from "../components/AppNotification.vue";

export default {
  components: {
    VueRecaptcha,
    AppNotification,
  },
  data() {
    return {
      recaptchaToken: null,
      phone: {
        number: "",
        callingCode: "%2B420",
      },
    };
  },

  mounted() {
    this.$store.commit("setNotifications", 
        null
    );
  },

  methods: {
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        await this.$axios.delete(
          `reminders?phoneNumber=${this.phone.number}&phoneCallingCode=${this.phone.callingCode}&reCaptchaToken=${this.recaptchaToken}`
        );

        this.$store.commit("setNotifications", {
          type: "success",
          messages: [
            `Odhlášení z registrace bylo úspěšné.`,
          ],
        });
      } catch (error) {
        const errors = error.response.data.errors;
        const errorMessage = errors.map((er) => {
          return er.message;
        });
        if (errors.filter((e) => e.code === "not_found").length > 0) {
          this.$store.commit("setNotifications", {
            type: "error",
            messages: [
              `Zadané telefonní číslo ještě nebylo zaregistrováno k notifikacím, prosím zkontrolujte, zda jste zadali správné telefonní číslo`,
            ],
          });
        } else {
          this.$store.commit("setNotifications", {
            type: "error",
            messages: errorMessage,
          });
        }
      }
    },

    async verifyRecaptchaMethod(value) {
      this.recaptchaToken = value;
    },

    recaptchaExecute() {
      this.$refs.recaptcha.reset();
      this.$refs.recaptcha.execute();
    },

    phoneRules(value) {
      if (value.length === 0) {
        this.validPhone = false;
        return "Prosím vlože svoje telefonní číslo";
      } else if (!/^\d{9}$/.test(value)) {
        this.validPhone = false;
        return "Telefonní číslo má špatný formát, telefonní číslo by mělo vypdat například takto: 777123456";
      } else {
        this.validPhone = true;
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
section {
  border: 1px solid gainsboro;
  padding: 2rem;
  margin-top: 1rem;
  background: white;
  border-radius: 0.5rem;

  .section-title {
    margin-bottom: 1rem;
    margin-top: 0.2rem;
    font-size: 1.3rem;
    color: #767676;
    font-weight: 500;
  }
}

.number-circle {
  border: 5px solid var(--v-primary-base);
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .number-circle {
    font-size: 1.2rem;
    height: 2.5rem;
    width: 2.5rem;
    border: 4px solid var(--v-primary-base);
  }
}
</style>
