<template>
  <v-app-bar app class="elevation-0 white">
    <v-container>
      <div class="d-flex align-center">
        <div @click="$router.push({ path: '/' })">
          <a href="/" class="logo">
            <h1><span class="primary--text">SOS</span>alergie</h1></a
          >
        </div>
        <v-spacer />
        <div>
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            outlined
            color="error"
            @click="$router.push({ name: 'Unsubscribe' })"
          >
            odhlásit se z odběru notifikací
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style>
.logo {
  text-decoration: none;
}
</style>
