<template>
  <v-app>
    <app-bar />
    <v-main>
      <v-container>
        <router-view />
        <div class="mt-4">
          <small>
            Nastavením připomínky expirace pera EpiPen prostřednictvím této
            webové stránky udělujete společnosti Viatris CZ s.r.o., IČ 03481778,
            se sídlem Evropská 2590/33c, Dejvice, 160 00 Praha 6, zapsané v
            obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka
            232034 (dále jako "Správce") dobrovolný souhlas se zpracováním
            osobních údajů dle nařízení Evropského parlamentu a Rady (EU) č.
            2016/679 ze dne 27. dubna 2016, o ochraně fyzických osob v
            souvislosti se zpracováním osobních údajů a o volném pohybu těchto
            údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně
            osobních údajů). Účelem zpracování osobních údajů získaných přímo od
            Vás v souvislosti s nastavením připomínky expirace je odeslání
            upozornění ve formě SMS zprávy na zadané telefonní číslo. Vaše
            osobní údaje budou zpracovávány maximálně po dobu nezbytnou k
            zaslání připomínky expirace dle Vámi zadaných údajů. Svůj souhlas
            můžete kdykoli odvolat tím, že se <a href="https://sosalergie.cz/odhlasit-se-z-odberu" target="_blank" rel="noopener noreferrer">odhlásíte</a> z odběru notifikací na
            této webové stránce. Více podrobností o podmínkách zpracování vašich
            osobních údajů naleznete v našem <a href="https://www.viatris.com/cs-cz/lm/czech-republic/prohl%C3%A1%C5%A1en%C3%AD-o-ochran%C4%9B-osobn%C3%ADch-%C3%BAdaj%C5%AF-spole%C4%8Dnosti-viatris" target="_blank" rel="noopener noreferrer">Prohlášení o ochraně osobních
            údajů</a>.
          </small>
        </div>
      </v-container>
      <cookies-setting
        :showCookiesModal="showCookiesModal"
        @onCookiesSetting="onCookiesSetting"
        @onSaveSettings="showCookiesModal = false"
      />
    </v-main>
    <div class="unsubscribe-link">
      <router-link
        v-if="$vuetify.breakpoint.smAndDown"
        outlined
        color="error"
        :to="{ name: 'Unsubscribe' }"
        @click="$router.push({ name: 'Unsubscribe' })"
      >
        Přejete se odhlásit se z odběru notifikací?
      </router-link>
    </div>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import CookiesSetting from "./components/CookiesSetting.vue";

export default {
  name: "App",
  components: {
    AppBar,
    CookiesSetting,
  },
  data() {
    return {
      showCookiesModal: false,
    };
  },
  methods: {
    onCookiesSetting() {
      this.showCookiesModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background: whitesmoke;
  .v-main {
    margin-bottom: 5rem;
  }
  ::v-deep .container {
    max-width: 60rem;
  }

  .unsubscribe-link {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}
</style>
