<template>
  <v-form class="reminder mt-8" ref="form" @submit.prevent>
    <div>
      <app-notification class="mt-4" />
    </div>

    <section>
      <div class="section-title">
        <div
          class="d-flex flex-column align-center flex-md-row align-md-start mb-4"
        >
          <div class="number-circle mr-md-4 mb-4 mb-md-0">1</div>
          <div class="mb-6">Zadejte Vaše telefonní číslo:</div>
        </div>
        <div>
          <v-text-field
            v-if="showPhoneInput"
            v-model="phone.number"
            :rules="[phoneRules]"
            outlined
            label="Zadejte telefonní číslo"
          >
          </v-text-field>
        </div>
      </div>

      <div class="text-right mt-8">
        <v-btn
          depressed
          class="black--text"
          color="primary"
          large
          :disabled="!(validEmail || validPhone)"
          @click="$vuetify.goTo('.second-section')"
          >Další krok</v-btn
        >
      </div>
    </section>

    <section v-if="validEmail || validPhone">
      <div class="section-title second-section">
        <div
          class="d-flex flex-column align-center flex-md-row align-md-start mb-4"
        >
          <div class="number-circle mr-md-4 mb-4 mb-md-0">2</div>
          <div class="mb-6">Máte u sebe k dispozici pero EpiPen?</div>
        </div>
        <div>
          <v-btn-toggle v-model="showExpirationForm" color="primary">
            <v-btn> Ne </v-btn>
            <v-btn> Ano </v-btn>
          </v-btn-toggle>
        </div>
      </div>

      <div class="text-right mt-8">
        <v-btn
          depressed
          class="black--text"
          color="primary"
          large
          :disabled="showExpirationForm === null"
          @click="$vuetify.goTo('.third-section')"
          >Další krok</v-btn
        >
      </div>
    </section>

    <section v-if="showExpirationForm === 1">
      <div class="section-title third-section">
        <div class="d-flex flex-column align-center flex-md-row align-md-start">
          <div class="number-circle mr-md-4 mb-4 mb-md-0">3</div>
          <div class="mb-6">
            Zadejte datum použitelnosti (expirace) Vašeho pera EpiPen
          </div>
        </div>
        <div>
          <div v-if="showScanner">
            <p class="text-body-1">
              Expiraci můžete zadat ručně nebo naskenováním kódu na krabičce.
            </p>
            <p class="text-body-1">
              Pro na naskenování kódu klikněte níže do okna pro zahájení
              skenování a namiřte fotoaparát na kód na krabičce. Naskenováním se
              doplní datum expirace automaticky.
            </p>
            <code-scanner @scannedResult="scannedResult" />
          </div>

          <div v-else>
            <img class="mt-8" src="../assets/images/epipen-exp.png" alt="" />
          </div>

          <expiration-date-selects
            :scannedExpirationDate="scannedExpirationDate"
            :loading="submitExpirationLoading"
            @expirationDate="submitExpirationDate"
          />
        </div>
      </div>
    </section>

    <section v-else-if="showExpirationForm === 0">
      <div
        class="d-flex flex-column align-center flex-md-row align-md-start mb-4"
      >
        <div class="number-circle mr-md-4 mb-4 mb-md-0">3</div>
        <div class="section-title third-section">
          Klikněte na „ZASLAT ODKAZ“. Na Vámi uvedené číslo zašleme SMS pro
          zadání data použitelnost (expirace) pera EpiPen.
        </div>
      </div>
      <div>
        <v-btn
          class="submit-btn"
          depressed
          :loading="submitWithoutExpirationLoading"
          large
          color="primary"
          @click="submitWithoutExpirationDate"
        >
          Zaslat odkaz
        </v-btn>
      </div>
    </section>

    <vue-recaptcha
      style="display: none"
      size="invisible"
      ref="recaptcha"
      sitekey="6LdKYKcgAAAAAECvyEpWvb3FLx9NRgoxuMRKLFFf"
      @verify="verifyRecaptchaMethod"
      @render="recaptchaExecute"
    ></vue-recaptcha>
  </v-form>
</template>

<script>
import CodeScanner from "../components/CodeScanner.vue";
import ExpirationDateSelects from "../components/ExpirationDateSelects.vue";
import { VueRecaptcha } from "vue-recaptcha";
import AppNotification from "../components/AppNotification.vue";

export default {
  components: {
    CodeScanner,
    ExpirationDateSelects,
    VueRecaptcha,
    AppNotification,
  },
  name: "Reminder",
  data() {
    return {
      showPhoneInput: true,
      showExpirationForm: null,
      showScanner: false,
      submitExpirationLoading: false,
      submitWithoutExpirationLoading: false,
      phone: {
        number: "",
        callingCode: "+420",
      },
      email: "",
      valid: false,
      validEmail: false,
      validPhone: false,
      recaptchaToken: null,
      scannedExpirationDate: null,
      steps: [
        {
          name: "Zadejte svůj kontakt",
        },
        {
          name: "Pokud již máte Epipen pero u sebe můžete rovnou zadat datum jeho expirace a my Vám včas připomene jeho výměnu.",
        },
        {
          name: "Pokud zadáváte kontakt za ně",
        },
        {
          name: "Až budete mít pero u sebe ",
        },
        {
          name: "Klikněte na link",
        },
      ],
    };
  },

  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.showScanner = true;
    }
  },

  methods: {
    async submitWithoutExpirationDate() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      const contact = this.phone.number
        ? { phone: this.phone }
        : { email: this.email };

      this.recaptchaExecute();

      const recaptchaToken = this.recaptchaToken;

      const contacForm = this.phone.number
        ? "Vaše telefonní číslo"
        : "Váš email";

      try {
        this.submitWithoutExpirationLoading = true,
        this.$store.commit("setNotifications", null);
        await this.$axios.put("reminders", {
          ...contact,
          recaptchaToken,
        });
        this.$store.commit("setNotifications", {
          type: "success",
          messages: [`Na ${contacForm} byly zaslány instrukce pro další krok`],
        });
        this.$vuetify.goTo(0);
      } catch (error) {
        const errorArray = error.response.data.errors;
        const errorMessage = errorArray.map((er) => {
          return er.message;
        });

        this.$store.commit("setNotifications", {
          type: "error",
          messages: errorMessage,
        });

        this.$vuetify.goTo(0);
      } finally {
        this.submitWithoutExpirationLoading = false;
      }
    },

    async submitExpirationDate(value) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      const contact = this.phone.number
        ? { phone: this.phone }
        : { email: this.email };

      const month = value.month;
      const expirationDate = new Date(value.year, month, 0);
      const monthLeadingZero = ("0" + (expirationDate.getMonth() + 1)).slice(
        -2
      );

      const epipenExpiresAt = `${expirationDate.getFullYear()}-${monthLeadingZero}-${expirationDate.getDate()}`;

      const payload = { ...contact, epipenExpiresAt: epipenExpiresAt };

      this.recaptchaExecute();

      const recaptchaToken = this.recaptchaToken;

      const contacForm = this.phone.number
        ? "Vaše telefonní číslo"
        : "Váš email";

      try {
        this.submitExpirationLoading = true;
        await this.$axios.put("reminders", {
          ...payload,
          recaptchaToken,
        });
        this.$store.commit("setNotifications", {
          type: "success",
          messages: [
            `Připomínky byly nastaveny. Na ${contacForm} bude před datem expirace EpiPen pera zaslána připomínka.`,
          ],
        });
        this.$vuetify.goTo(0);
      } catch (error) {
        const errorArray = error.response.data.errors;
        const errorMessage = errorArray.map((er) => {
          return er.message;
        });

        this.$store.commit("setNotifications", {
          type: "error",
          messages: errorMessage,
        });

        this.$vuetify.goTo(0);
      } finally {
        this.submitExpirationLoading = false;
      }
    },

    recaptchaExecute() {
      this.$refs.recaptcha.reset();
      this.$refs.recaptcha.execute();
    },

    async verifyRecaptchaMethod(value) {
      this.recaptchaToken = value;
    },

    phoneRules(value) {
      if (value.length === 0) {
        this.validPhone = false;
        return "Prosím vlože svoje telefonní číslo";
      } else if (!/^\d{9}$/.test(value)) {
        this.validPhone = false;
        return "Telefonní číslo má špatný formát, telefonní číslo by mělo vypdat například takto: 777123456";
      } else {
        this.validPhone = true;
        return true;
      }
    },

    emailRules(value) {
      if (value.length === 0) {
        this.validEmail = false;
        return "Prosím vlože svůj e-mail";
      } else if (!/.+@.+\..+/.test(value)) {
        this.validEmail = false;
        return "E-mail má špatný formát";
      } else {
        this.validEmail = true;
        return true;
      }
    },

    phoneEmailSwitch() {
      this.$refs.form.resetValidation();
      this.showPhoneInput = !this.showPhoneInput;
      this.phone.number = "";
      this.email = "";
    },

    scannedResult(event) {
      this.scannedExpirationDate = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-text {
  cursor: pointer;
  color: var(--v-anchor-base);

  &:hover {
    text-decoration: underline;
  }
}

.number-circle {
  border: 5px solid var(--v-primary-base);
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

section {
  border: 1px solid gainsboro;
  padding: 2rem;
  margin-top: 1rem;
  background: white;
  border-radius: 0.5rem;

  .section-title {
    margin-bottom: 1rem;
    margin-top: 0.2rem;
    font-size: 1.3rem;
    color: #767676;
    font-weight: 500;
  }
}

h1 {
  margin-bottom: 1rem;
}

.submit-btn {
  width: 100%;
}

@media screen and (min-width: 960px) {
  .number-circle {
    font-size: 1.2rem;
    height: 2.5rem;
    width: 2.5rem;
    border: 4px solid var(--v-primary-base);
  }
}
</style>
