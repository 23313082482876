import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e8c10e',
        anchor: '#1976d2',
      },
    },
    options: { customProperties: true },
  },
});
